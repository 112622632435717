.container {
    border: var(--border);
    margin: 0 4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
    font-size: 12px;

    transition: transform .125s ease-in-out;
}

.selectedContainer {
    background-color: var(--thirdColor);
    border: var(--border);
    margin: 0 4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
    font-size: 12px;

    transition: transform .125s ease-in-out;
}

.container:hover,.selectedContainer:hover {
    transform: scale(1.15) rotate(10deg);
}

.container:active,.selectedContainer:active  {
    transition: transform .07s ease-in-out;
    transform: scale(1.25) rotate(-10deg);
}