.container {
    margin: 8px 23px;
}

.count {
    text-align: center;
    font-size: 14px;
    font-weight: 200;
}

.textContainer {
    /*background: red;*/
    font-size: 20px;
    color: var(--textColor);
    padding: 8px 14px;
    /*border: rgba(0, 0, 0, 0.5) solid 1px;*/
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 var(--shadowColor);
    margin-top: 4px;
    font-weight: 600;
    /*box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, .25);*/

    cursor: pointer;

    transition: transform .125s ease-in-out;
}

.textContainer:hover {
    transform: scale(1.05) translateY(-4px);
}

.textContainer:active {
    transition: transform .05s ease-in-out;
    transform: scale(1) translateY(-4px);
}