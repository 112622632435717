.div {
  transition: transform .125s ease-in-out;
  cursor: pointer;
  margin-right: 8px;
  /*border: rgba(0, 0, 0, 0.5) solid 1px;*/
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
  border-radius: 4px;
  /*margin: 4px 0;*/
  /*padding: 0 4px;*/
  width: auto;
}

.div > .select {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  border: none;
}

.div > .select > select {
  /*display: none;*/
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}