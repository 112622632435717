.wrapper {
  display: flex;
}

.container {
  /*margin-top: 20%;*/

  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  width: 50%;

  /*top: 45%;*/

  /*position: fixed;*/
  /*top: 45%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*!*font-weight: 400;*!*/
}

/*@media only screen and (orientation: landscape) {*/

.count {
  text-align: center;
  font-weight: 400;
  font-size: 32px;
  margin: 25px 0;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  max-width: 320px;
  width: 40%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  margin-bottom: 100px;
  margin-left: 12px;
  margin-right: 12px;
}

.text {
  margin: 0 10px;
  text-align: center;
}

.liveViewContainer {
  width: 100%;

  display: flex;
  justify-content: right;
  align-items: center;

  margin-top: 10px;
}

.liveViewContainer div {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 6px;
}

.liveViewContainer div span {
  margin-right: 5px;

  font-size: 18px;
}


@media only screen and (max-width: 1100px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    width: 100%;
  }

  .icon {
    width: 70%;
  }
}