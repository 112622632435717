.container {
    position: absolute!important;
    cursor: pointer;
    right: 18px;
    top: 13px;

    justify-content: center;
    align-items: center;
    display: flex;

    /*border: rgba(0, 0, 0, 0.5) solid 1px;*/
}