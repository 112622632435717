.container {
    margin: 8px 23px;
    padding: 8px 0;
    background-color: var(--disabledUpgrade);
    border-radius: 4px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    /*transition: transform .125s ease-in-out;*/
}

.container p {
    font-size: 14px;
    text-align: center;
    color: var(--textColor);
    margin: 6px 0 0 0;
}

/*.container p span {*/
/*    color: #0060FF;*/
/*    font-weight: 600;*/
/*}*/

.container:hover > .icon  {
    /*transform: scale(1.5);*/

    animation-name: example;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: ease-in-out;
}


@keyframes example {
    0%   {transform: rotate(0);}
    33% {transform: rotate(-30deg);}
    66%   {transform: rotate(30deg);}
    100%   {transform: rotate(0);}
}