.wrapper {
  display: flex;
}

.container {
  position: absolute;
  top: 50%;
  /*left: 50%;*/
  transform: translate(0, -50%);
  width: 100%;

  border: var(--border);
  border-left: none;
  border-radius: 0 8px 8px 0;
  max-width: 370px;
  height: 65%;
  max-height: 757px;
}

.clickDisplay {
  position: absolute;
  top: 87%;
  /*left: 50%;*/
  transform: translate(0, -50%);

  /*margin-left: auto;*/
  /*margin-right: auto;*/

  width: 100%;

  /*border: var(--border);*/
  /*border-left: none;*/
  /*border-radius: 0 8px 8px 0;*/
  /*max-width: 370px;*/
  /*height: 65%;*/
  /*max-height: 757px;*/

  text-align: center;
  /*font-weight: 700;*/
  font-size: 34px;
}

.Topbar {
  margin: 8px 23px;
}

.Topbar header {
  font-size: 28px;
  font-weight: bold;
  font-style: italic;

  /*
    ToDo: Create Responsible header!
   */

  margin-right: 140px;
}

@media only screen and (max-width: 1100px) {
  .container {
    background-color: var(--backgroundColor);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    border-radius: 8px;
    border: var(--border);
  }
}

@media only screen and (max-width: 950px) {
  .container {
    background-color: var(--backgroundColor);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    /*background-color: #fff;*/
    border-radius: 8px;
    border: var(--border);
  }
}