.div {
  display: flex;
  padding: 12px 12px;

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /*background-color: rgba(217,217,217,.25);*/
  text-align: center;
}

.centerText {
  justify-content: center;
  align-items: center;

  /*margin-left: auto;*/
  /*margin-right: auto;*/
}

.center {
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*width: 100px;*/
}

.right {
  margin-left: auto;
  margin-right: 16px;
  display: flex;
}

.center {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/*
  TODO: Center text in footer
  "This website is created for educational purposes"
 */