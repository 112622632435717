/*@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*/


:root {
    --backgroundColor: #fff;
    --secondaryColor: rgba(217,217,217,.25);
    --thirdColor: rgba(217, 217, 217, 0.6);
    --shadowColor: rgba(0, 0, 0, .25);
    --logoColor: #050505;
    --border: rgba(0, 0, 0, 0.5) solid 1px;
    --textColor: rgba(0,0,0,0.55);
    --disabledUpgrade: rgba(28, 27, 31, .1);
}

/*html {*/
/*    touch-action: none;*/
/*}*/

* {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
}