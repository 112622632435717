.div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg {
  width: 48px;
}

.text {
  margin-left: 4px;
  font-size: 36px;
  color: var(--logoColor);
  font-weight: 200;
  word-wrap: break-word;
  text-shadow: 4px 4px var(--shadowColor);
}

@media only screen and (max-width: 1100px) {
  .text {
    font-size: 24px;
  }

  .svg {
    width: 32px;
  }
}