.div {
  display: flex;
  background-color: var(--secondaryColor);
  padding: 14px 30px;
}

.rightDiv {
  position: absolute!important;
  cursor: pointer;
  right: 12px;
  top: 25px;

  justify-content: center;
  align-items: center;
}

.exportimportButton {
  border: var(--border);
  margin: 0 4px;
  border-radius: 4px;
  /*width: 24px;*/
  padding: 0 10px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-size: 12px;
  transition: transform .125s ease-in-out;
}

.exportimportButton:hover {
  transform: scale(1.15) rotate(10deg);
}

.exportimportButtonMOBILE {
  /*border: var(--border);*/
  margin: 0 4px;
  /*border-radius: 4px;*/
  /*width: 24px;*/
  /*padding: 0 10px;*/
  height: 24px;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-size: 12px;
  transition: transform .125s ease-in-out;
}

.exportimportButtonMOBILE:hover {
  transform: scale(1.15) rotate(10deg);
}

@media only screen and (max-width: 1100px) {
  .rightDiv {
    top: 17px;
  }
}