.div {
  transition: transform .125s ease-in-out;
  cursor: pointer;
  margin-right: 8px;
}

.div:hover {
  transform: scale(1.15) rotate(10deg);
}

.div:active {
  transition: transform .07s ease-in-out;
  transform: scale(1.25) rotate(-10deg);
}